<template>
    <div :class="divClass">
        <h3 class="mb-2">{{ title }}</h3>
        <v-list dense>
            <template v-for="(item, index) in factors">
                <v-list-item :key="index">
                    <v-list-item-icon>
                        <v-icon color="warning darken-2" class="mr-2"
                            >mdi-chevron-right-circle</v-icon
                        >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-icon>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>
<script>
export default {
    name: "credit-factors",
    components: {},
    props: {
        factors: {
            type: Array,
            required: true,
        },
        divClass: {
            type: String,
            required: false,
            default: "ml-7 mt-4",
        },
        title: {
            type: String,
            required: false,
            default: "Factors",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>
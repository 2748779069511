var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.divClass },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.factors, function(item, index) {
            return [
              _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "warning darken-2" }
                        },
                        [_vm._v("mdi-chevron-right-circle")]
                      ),
                      _c("v-list-item-title", [_vm._v(_vm._s(item))])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }